.appeal-message {
	border: 1px solid #ced4da;
	border-radius: 6px;
	color: #2b2b2b;
	padding: 8px 30px 8px 15px;
}

.curator{
	margin: 15px;
	padding-bottom: 30px;
}

@media screen and (max-width: 600px) {
  .phoneNumber {
		margin-top: 15px;
  }
}

.sms-temp {
	max-width: 65%;
}

.sended-temp {
	margin-left: auto;
}

.sms {
	border-radius: 10px;
	color: #fff;
	display: table;
	margin-top: 10px;
	padding: 10px;
}

.coming {
	background-color: #6e59a0;
	position: relative;

	&::after {
		border: 10px solid transparent;
		border-bottom: 10px solid #6e59a0;
		bottom: 0;
		content: '';
		left: -8px;
		position: absolute;
	}
}

.sended {
	background-color: #5f4d83;
	margin-left: auto;
	position: relative;

	&::after {
		border: 10px solid transparent;
		border-bottom: 10px solid #5f4d83;
		bottom: 0;
		content: '';
		position: absolute;
		right: -8px;
	}
}
