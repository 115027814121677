@import '../../../../assets/styles/inc/global/variables';

._full {
	&.cmp-linear-tree {
		.inner-wrap {
			height: 100% !important;
		}
	}
}

.block-translate {
	background-color: #fff;
	width: 30px;
	height: 60px;
	display: flex;
	margin-left: 30px;
	position: absolute;
	margin-top: 30px;
	border-radius: 6px;
	flex-direction: column;
	box-shadow: 0px 0px 7px 0px rgba(34, 60, 80, 0.2);
	z-index: 2;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 3px;
		border-radius: 10px;
		height: 1px;
		width: 80%;
		background-color: #f5f5f5;
	}

	button {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.cmp-linear-tree {
	transition: unset !important;

	.card-header {
		li {
			&:not(:last-child) {
				&:after {
					content: '>';
					display: inline-block;
					margin: 0 10px;
				}
			}
		}
	}

	.inner-wrap {
		height: 500px;

		.react-transform-component {
			height: 100%;
			width: 100%;
		}

		.react-transform-wrapper {
			height: 100%;
			width: 100%;
		}
	}

	.child-wrap {
		&:before {
			background: #d0d4da;
			content: '';
			height: 0.75rem;
			left: 15px;
			position: absolute;
			top: 0;
			width: 0.25rem;
		}
	}

	.tree-wrap {
		position: relative;

		& > .flex {
			& > .flex {
				&._last {
					& > .child-inner-wrap {
						flex-direction: row !important;
						padding-left: 0 !important;

						& > .flex {
							&:before {
								left: 15px;
							}

							&:not(:first-child) {
								&:before {
									display: none;
								}

								.node-wrap {
									&:before {
										left: -1000px;
										width: 1000px;
									}
								}
							}
						}
					}
				}

				&:before {
					border-top: 0.25rem solid #d0d4da;
					content: '';
					height: 0.75rem;
					position: absolute;
					top: -0.75rem;
				}

				&:first-child {
					&:before {
						border-left: 0.25rem solid #d0d4da;
						left: 15px;
						right: 0;
					}
				}

				&:nth-child(2) {
					&:before {
						left: 0;
						right: 0;
					}

					.node-wrap {
						&:before {
							background: #d0d4da;
							content: '';
							height: 0.75rem;
							left: 50%;
							position: absolute;
							top: -0.75rem;
							transform: translateX(-50%);
							width: 0.25rem;
						}
					}
				}

				&:last-child {
					&:before {
						border-right: 0.25rem solid #d0d4da;
						left: 0px;
						right: auto;
						width: 20px;
					}
				}
			}
		}
	}

	.child-inner-wrap {
		& > .flex {
			&:before {
				background: #d0d4da;
				content: '';
				height: 1025px;
				left: -0.8rem;
				position: absolute;
				top: -1010px;
				width: 0.25rem;
			}
		}

		.node-wrap {
			&:before {
				background: #d0d4da;
				content: '';
				height: 0.25rem;
				left: -0.8rem;
				position: absolute;
				top: 15px;
				width: 0.8rem;
			}
		}
	}

	.open-full {
		background: rgba(0, 0, 0, 0.8);
	}

	.close-full {
		display: none;
	}

	&._full {
		border-radius: 0 !important;
		bottom: 0;
		height: 100% !important;
		left: 0;
		margin-bottom: 0 !important;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 1000;

		.open-full {
			display: none !important;
		}

		.close-full {
			background: $colorBg;
			border-bottom: 1px solid $colorBgDark;
			display: flex;
			left: 0;
			right: 0;
			top: 0;
			z-index: 1;
		}
	}

	&._touch {
		height: 300px;
	}

	@media #{$mediaSm} {
		&._touch {
			height: 160px;
		}
	}
}
