@import "/src/assets/styles/inc/global/variables";

#page-auth {
	.tab-wrap {
		background: #ff66660f;
		color: $colorText;

		.button {
			cursor: pointer;
			display: block;
			padding: 6px 0;

			&._active {
				background: #5e0f0f;
				color: #ffff;
			}
		}
	}
}
