@import "../../../assets/styles/inc/global/variables";
.tooltip{
	height:100px;
	width:75px;
	border-bottom: unset !important;
    background: #fff;
    overflow-y: scroll;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.14);
    padding: 5px;
    border-radius: 6px;
		z-index: 9999;
	position: absolute;
	top:-93px;
	&::-webkit-scrollbar {
		width: 3px;               /* ширина scrollbar */
	  }
	  &::-webkit-scrollbar-track {
		background: transparent;        /* цвет дорожки */
	  }
	  &::-webkit-scrollbar-thumb {
		background-color: rgb(97, 25, 25);    /* цвет плашки */
		border-radius: 20px;       /* закругления плашки */
		border: 3px solid transparent;  /* padding вокруг плашки */
	  }
}
.waitTooltip{
	width: 60px;
	height: 60px;
	top:-93px;
	position: absolute;
}
.cmp-progress-table {
	table {
		td {
			&._active {
				color: #007100;
			}

			&._wait {
				color: fade-out($colorText, 0.5);
			}
		}
	}

	@media (max-width: 480px) {
		table {
			thead,
			tbody {
				tr {
					th,
					td {
						padding: 10px;
					}

					.first-table-th,
					.name-table {
						white-space: normal;
						width: 200px;
					}
				}
			}
		}
	}
}
