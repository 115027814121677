@import "../../../assets/styles/inc/global/variables";

#page-fast-launch {
	.header {
		img {
			width: 140px;
		}
	}

	.social-links {
		.icon {
			border-radius: 3px;
			height: 28px;
			width: 28px;
		}

		.phone {
			background: #0d4dc1;
		}

		.vk {
			background: #45668e;
		}

		.fb {
			background: #1877f2;
		}

		.sk {
			background: #00aff0;
		}

		.tg {
			background: #08c;
		}
	}

	.footer {
		color: rgba(47, 44, 55, 0.5);

		.notification-text {
			line-height: 1.5;
			margin: 40px 0;
			z-index: 1;

			&:before {
				background: $colorBgDark;
				border-radius: 20px;
				bottom: -30px;
				content: "";
				left: -30px;
				position: absolute;
				right: -30px;
				top: -30px;
				z-index: -1;
			}
		}
	}

	#fast-launch-btn {
		animation: pulsate 2s infinite;
	}

	@media #{$mediaMd} {
		.footer {
			.small-text {
				@include text-overflow(2);
			}

			.notification-text {
				padding: 0 15px;

				&:before {
					border-radius: 14px;
					bottom: -15px;
					left: 0;
					right: 0;
					top: -15px;
				}
			}
		}
	}
}

@keyframes pulsate {
	0% {
		box-shadow: 0 0 0 0 #7113137f;
	}
	50% {
		box-shadow: 0 0 5px 14px rgba(0, 0, 0, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
